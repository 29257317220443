import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './Footer.css';
import './../../font-awesome/css/all.css';

export const Footer = () => {

    return (
        <footer className="footer" >
            <Container>
                <a href="http://twitter.com" target="_blank" className="fab fa-twitter-square"></a>
                <a href="http://facebook.com" target="_blank" className="fab fa-facebook-square"></a>
                <a href="http://linkedin.com" target="_blank" className="fab fa-linkedin"></a>
            </Container>
            <link href="https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap" rel="stylesheet" />
        </footer>
    );
}
