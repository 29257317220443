import React, { useState } from 'react';
import * as Validation from 'react-hook-form';

import { Jumbotron } from './../../../Modules/Jumbotron/Jumbotron';
import { SectionRegular } from './../../../Modules/Section/SectionRegular';
import { FadeIn } from './../../../Modules/FadeIn/FadeIn';
import { Faq } from './Faq';


export const Contact = () => {
    const [finished, setFinished] = useState();
    const { register, handleSubmit, watch, errors } = Validation.useForm();

    let submitForm = (data) => {
        console.log(data);
        let form_data = new FormData();

        for (let key in data) {
            form_data.append(key, data[key]);
        }

        setFinished({
            email: data['email']
        })

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LdJDbEZAAAAAP6lAjEaywKpB6klYMLL4mX0Bpqg', { action: 'submit' }).then(function (token) {
                form_data.append('RecaptchaToken', token);

                fetch(window.location.origin + "/api/contact", {
                    method: 'POST',
                    body: form_data
                })
                    .then(res => {
                    })
                    .finally(() => {
                        window.scrollTo(0, 0);
                    });
            });
        });
    }

    return (
        <div>
            <Jumbotron small="true">
                <h1>Contact</h1>
            </Jumbotron>
            <SectionRegular>
                <article className="col-sm-6">
                    <FadeIn>
                        <h3>Veelgestelde vragen</h3>
                        <Faq>Zijn er veel vragen op deze website?</Faq>
                        <Faq>Hoe zit het met mijn contract?</Faq>
                        <Faq>Waar kan ik de aanmeld pagina vinden?</Faq>
                        <Faq>Ik heb geen geld, wat nu?</Faq>
                        <Faq>Een vriend van mij is een auto, hoe kan dat?</Faq>
                        <Faq>Zijn koala's dieren of vruchten?</Faq>
                    </FadeIn>
                </article>


                <article className="col-sm-6">
                    <FadeIn>
                        {finished != null ?
                            <article className="col-sm-12">
                                <h3>Contact formulier succesvol verzonden.</h3>
                                <div>{finished.email}</div>
                            </article>
                            :
                            <>
                                <h3>Contact</h3>
                                <p>Heeft u verder nog een vraag? stel hem direct.</p>
                                <form onSubmit={handleSubmit(submitForm)}>

                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="md-form mb-0">
                                                <label htmlFor="name" className="">Naam</label>
                                                <input type="text" id="name" name="name" className="form-control" ref={register({ required: true })} />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="md-form mb-0">
                                                <label htmlFor="email" className="">E-mail</label>
                                                <input type="text" id="email" name="email" className="form-control" ref={register({ required: true })} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="md-form mb-0">
                                                <label htmlFor="subject" className="">Onderwerp</label>
                                                <input type="text" id="subject" name="subject" className="form-control" ref={register({ required: true })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <div className="col-md-12">

                                            <div className="md-form">
                                                <label htmlFor="content">Bericht</label>
                                                <textarea type="text" id="content" name="content" rows="10" className="form-control md-textarea" ref={register({ required: true })}></textarea>
                                            </div>

                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary">Versturen</button>
                                </form>
                            </>
                        }
                    </FadeIn>
                </article>

            </SectionRegular>
        </div>
    );
}
