import React, { useState, useEffect } from 'react';
import { SectionRegular } from '../Section/SectionRegular';

export const AdminLogin = (props) => {
    const { adminLogin } = props;

    return (
        <div>
            <SectionRegular className="text-center">
                <article className="col-sm-12">
                    <h2>Login</h2>
                </article>
                <article className="offset-sm-3 col-sm-6">
                    <form id="login-form" onSubmit={adminLogin}>
                        <input type="text" className="form-control" name="username" id="username" aria-describedby="emailHelp" placeholder="username" />
                        <input type="password" className="form-control" name="password" id="password" aria-describedby="emailHelp" placeholder="password" />
                        <input type="submit" className="text-center btn btn-primary" />
                    </form>
                </article>
            </SectionRegular>
        </div >
    );
}