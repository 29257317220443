import React, { useState, useEffect } from 'react';
import { Collapse } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { Jumbotron } from '../../../Modules/Jumbotron/Jumbotron';
import { Card } from '../../../Modules/Card/Card';
import { FadeIn } from '../../../Modules/FadeIn/FadeIn';
import { SectionRegular } from '../../../Modules/Section/SectionRegular';
import { search, stateObjects } from '../../../Modules/Helpers/Helpers';
import { useT } from '../../../Modules/Helpers/Translation';
import CheckboxList from '../../../Modules/SearchDropdown/CheckboxList';

import './Offers.css';

export const Offers = () => {
    const { transl } = useT();
    const [collapsed, setCollapsed] = useState(!isMobile);
    const [offers, setOffers] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [levels, setLevels] = useState([]);
    const [displayOffers, setDisplayOffers] = useState([]);
    const [checkboxFilter, setCheckboxFilter] = useState([]);
    const [searchFilter, setSearchFilter] = useState([]);

    useEffect(() => {
        fetch(window.location.origin + "/api/offer")
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOffers(stateObjects(result));
                    setDisplayOffers(result);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, []);

    useEffect(() => {
        fetch(window.location.origin + "/api/discipline")
            .then(res => res.json())
            .then(
                (result) => {
                    setDisciplines(stateObjects(result));
                },
                (error) => {
                    console.log(error);
                }
            )
    }, []);

    useEffect(() => {
        fetch(window.location.origin + "/api/level")
            .then(res => res.json())
            .then(
                (result) => {
                    setLevels(stateObjects(result));
                },
                (error) => {
                    console.log(error);
                }
            )
    }, []);

    const cards = displayOffers.map((item, key) =>
        <Card key={item.title} item={item} />
    );

    useEffect(() => {
        setDisplayOffers(search(offers, searchFilter + checkboxFilter.map(x => " " + x)));
    }, [checkboxFilter, searchFilter]);

    let handleKeyUp = (e) => {
        setSearchFilter(e.target.value);
    }

    return (
        <div>
            <Jumbotron small="true">
                <h1>Opdrachten</h1>
            </Jumbotron>

            <SectionRegular className="pb-2">
                <div className="col-sm-12">
                    <h3>Uw opdracht op maat</h3>
                    <p>But also the leap into electronic typesetting, remaining essentially unchanged.</p>
                    <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                </div>
            </SectionRegular>

            <section id="filter" className="container-fluid full">
                <div className="container">
                    <article>
                        <div className="row">
                            <div className="col-sm-12">
                                <FadeIn>
                                    <h3>Verfijn zoekresultaat</h3>

                                    <div className="form-group p-0">
                                        <input type="text" className="form-control" id="inputzoek" aria-describedby="zoekHelp" placeholder="Zoekterm" onKeyUp={handleKeyUp} />
                                    </div>
                                </FadeIn>
                            </div>
                        </div>
                        <Collapse isOpen={collapsed}>
                            <div className="row mt-3 pl-4 pr-4">
                                <div className="col-sm-4">
                                    <FadeIn>
                                        <CheckboxList values={disciplines.map(x => x.title)} stateObject={checkboxFilter} setStateObject={setCheckboxFilter} label={transl("discipline")} />
                                    </FadeIn>
                                </div>
                                <div className="col-sm-4">
                                    <FadeIn>
                                        <CheckboxList values={levels.map(x => x.title)} stateObject={checkboxFilter} setStateObject={setCheckboxFilter} label={transl("level")} />
                                    </FadeIn>
                                </div>
                                <div className="col-sm-4">
                                    <FadeIn>
                                        <CheckboxList values={['Verlengbaar']} stateObject={checkboxFilter} setStateObject={setCheckboxFilter} label={transl("misc")} />
                                    </FadeIn>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse isOpen={isMobile}>
                            <div className="btn" onClick={() => { setCollapsed(!collapsed) }} ><strong>Filters </strong><i className="fas fa-angle-right"></i></div>
                        </Collapse>
                    </article>
                </div>
            </section>
            <section className="container-fluid full cardblock-container">{cards}</section>
        </div >
    );
}