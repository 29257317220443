import React from 'react';

export const abstract = (
    <>
        <p>Resourcepoint is voor het Rijksvastgoedbedrijf in Den Haag op zoek naar een <strong>interim Projectleider Vastgoed.</strong> Het is een full-time opdracht (36 uur/week) voor de duur van één jaar die begint in oktober/november 2020. De werklocatie is in verband met corona zowel Den Haag als vanuit huis.</p>
    </>
);

export const content = (
    <>
        <h2>Organisatie</h2>
        <p>Het Rijksvastgoedbedrijf is de grootste opdrachtgever in Nederland op het gebied van vastgoed. Binnen het Rijk beheren zij vrijwel al het Rijksvastgoed. Dit omvat 94.000 hectare aan gronden en 12,4 miljoen m² bruto vloeroppervlak aan gebouwen. Ze ontwikkelen en beheren onder meer rechtbanken, kazernes, magazijnen, kantoren, musea, gevangenissen, gerechtsgebouwen, paleizen en monumenten.</p>
        <p>Het Rijksvastgoedbedrijf werkt samen met marktpartijen, kennisinstituten en andere opdrachtgevers aan een vitale en duurzame bouwsector. Dit doen ze door gelijkwaardig partnerschap en onderlinge kennisuitwisseling. De afdeling inkoop en contractmanagement zorgt onder meer voor het contracteren van alle partners in het bouwproces, zoals adviseurs, aannemers en architecten.</p>
        <p>Contracten in diverse vormen (nieuwbouwcontracten, onderhoudscontracten en geïntegreerde contracten) van diverse complexiteit met diverse marktpartijen vormen voor het Rijksvastgoedbedrijf de basis voor haar dienstverlening naar haar gebruikers. Dit vraagt om professioneel contractmanagement, met focus op gezamenlijke resultaten en meetbare prestaties op het gebied van kwaliteit en representativiteit. Met de belangen van de overheid als richtsnoer, geef je als contractmanager bij het Rijksvastgoedbedrijf vorm aan uniform en voorspelbaar contractmanagement richting de opdrachtnemers. Keuzes die je maakt hebben een maatschappelijke impact. Dat betekent dat jij je rekenschap geeft van de gebruikers- en marktbelangen èn van publieke waarden als duurzaamheid, integriteit en eerlijke concurrentie.</p>
        <p>Het Rijksvastgoedbedrijf staat voor een mooie en flinke uitdaging, want de komende jaren zullen veel nieuwe projecten en programma’s voor nieuwbouw, onderhoud, renovatie en sloop van vastgoed en infrastructuur op de markt worden gezet. Wij passen hierbij verschillende contractvormen toe. Het behalen van doelen, aantoonbare prestaties en kwaliteitsborging vragen steeds meer aandacht vanuit contractmanagement.</p>
        <h2>Functie</h2>
        <p>Je bent verantwoordelijk voor het contractmanagement van de contracten, van (regionale) raamcontracten op traditionele voorwaarden, tot geïntegreerde contracten als UAV-gc of DBM. Je levert in de teams als contractmanager een bijdrage in de voorbereidings- en realisatie- en exploitatiefase van het vastgoed. Op het gebied van contractinhoudelijke vragen ben je het centrale aanspreekpunt voor de collega’s. Hierbij laat je je adviseren door technische, financiële en juridische collega’s. Je bent adviseur, onderhandelaar, beslisser en probleemoplosser ineen, met een haarscherp gevoel voor verhoudingen en belangen. Als contractmanager zorg je onder meer voor:</p>
        <ul>
            <li>het bewaken van een constructieve en zakelijke samenwerking;</li>
            <li>het (laten) leveren van technische en/of juridische adviezen;</li>
            <li>toezicht op het kwaliteitsmanagement van de opdrachtnemer;</li>
            <li>bespreekbaar maken en oplossen van contractuele knelpunten;</li>
            <li>uniformiteit in doen en laten binnen de gehele portefeuille van contracten;</li>
            <li>verdere professionalisering van het contractmanagement.</li>
        </ul>
        <p>Verder ben je onder andere bezig met het opstellen van contractbeheersplannen, de beoordeling van geleverde diensten en/of producten, het voorkomen en beheersen van risico’s en aansprakelijkheid en het voeren van eventuele onderhandelingen. Ook ben je verantwoordelijk voor het inrichten van de gesprekcyclus met de leverancier. Je kent de gebruikersorganisaties en onderhoudt daar contacten met de tactisch- en operationele inkopers/contractmanagers.</p>
        <h2>Functie eisen en wensen</h2>
        <p><strong>Opleiding</strong><br />Je hebt minimaal aantoonbare HBO werk- en denkniveau. Een aanvullende opleiding voor CATS-CM is een pré.</p>
        <p><strong>Kennis en ervaring</strong><br />Je hebt werkervaring opgedaan met contractmanagement van meerdere grotere contracten, met diverse klanten, bij voorkeur in de utiliteitsbouw of infrastructuur. Je hebt kennis van de relevante wet- en regelgeving en procedures en de standaard contractvoorwaarden. Je bent in staat om specifieke materie en marktkennis en kennis op het gebied van contractvorming, verbintenissenrecht en jurisprudentie in de praktijk toe te passen, zodanig dat doelmatigheid en rechtmatigheid gewaarborgd zijn. Je:</p>
        <ul>
            <li>beschikt over HBO werk- en denkniveau;</li>
            <li>contractmanagement ervaring in de vastgoedsector;</li>
            <li>goed kunt analyseren, plannen en organiseren;</li>
            <li>kunt effectief samenwerken in interdisciplinaire teams.</li>
        </ul>
        <h2>Aanbod</h2>
        <p>Voor deze opdracht bij [KLANT] is het mogelijk om als ZZP'er of op projectcontract te werken. Er geldt een marktconform salaris of uurtarief.</p>
        <p>of</p>
        <p>Voor deze opdracht [KLANT] is het alleen mogelijk om op projectcontract of via detachering te werken (in verband met de wet DBA zal er een tijdelijk dienstverband -een project-contract- worden opgesteld met uitstekende primaire arbeidsvoorwaarden)</p>
        <h2>Interesse?</h2>
        <p>Ben je enthousiast geworden van deze opdracht en voldoe je aan de gestelde eisen en wensen? Reageer dan via de sollicitatie button.<br /><br />Heb je interesse of vragen over deze vacature? Neem dan contact met ons op via matching@resourcepoint.nl of solliciteer via de onderstaande knop.</p>
    </>
);