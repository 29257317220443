import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { search, normalizeDate } from './../../Modules/Helpers/Helpers';
import { useT } from './../../Modules/Helpers/Translation';
import { Container } from 'reactstrap';

export const Table = (props) => {
    const { transl } = useT();
    const {
        items,
        exempt,
        subList,
        itemname,
        allow,
        removeAdd,
        booleanList
    } = props;

    const [itemsInState, setItemsInState] = useState(items);

    let downloads = ["cv"];
    let booleans = [].concat(booleanList);

    let tbody = [];

    let handleChild = (propName, iteration, item) => {
        if (propName.indexOf("_id") > 0 && subList != null) {
            let curSublist = (subList.filter(e => propName.indexOf(e.name) !== -1))[0];
            let content = [];
            if (curSublist) {
                curSublist.fields.forEach(field => {
                    if (item[curSublist.name + "_" + iteration + "_" + field]) {
                        content.push(<span> {item[curSublist.name + "_" + iteration + "_" + field]} </span>);
                    }
                });

                if (content.length > 0) {
                    return (
                        <a href={"/sudo/application/" + item[curSublist.name + "_" + iteration + "_id"]} className={(item[curSublist.name + "_" + iteration + "_clientSubmit"] ? "clientSubmit" : "") + " " + (item[curSublist.name + "_" + iteration + "_shortlist"] ? "shortlist" : "") + " ui label"}>
                            { content}
                        </a >
                    );
                }
            }
        }
    }

    itemsInState.forEach((item, key) => {
        let row = [];
        let subRows = [];
        for (var propName in item) {
            if (!(propName.indexOf("_") > 0) || allow.includes(propName)) {
                if (propName.toLowerCase().indexOf("date") !== -1) {
                    item[propName] = normalizeDate(item[propName]);
                }

                if (!exempt.includes(propName)) {
                    if (downloads.includes(propName)) {
                        row.push(<td><a href={"/api/client/download/" + item.id} download>Download</a></td>);
                    } else if (booleans.includes(propName)) {
                        row.push(<td>{item[propName] ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}</td>);
                    } else {
                        row.push(<td><Link to={"/sudo/" + itemname + "/" + item.id} >{item[propName]}</Link></td>);
                    }
                }
            } else {
                let content = handleChild(propName, subRows.length, item);
                if (content) {
                    subRows.push(content);
                }
            }
        }

        tbody.push(<tr key={key}>{row}</tr>);
        if (subRows.length > 0) {
            tbody.push(<tr><td colSpan="100%">{subRows}</td></tr>);
        }
    });

    let headerRow = [];
    for (var propName in items[0]) {
        if (!exempt.includes(propName) && !(propName.indexOf("_") > 0) || allow.includes(propName)) {
            headerRow.push(<th key={propName}>{transl(propName)}</th>);
        }
    }
    let addLocation = "/sudo/" + itemname + "/add";
    let placeholder = "Zoek naar " + transl(itemname + "s");

    let handleKeyUp = (e) => {
        setItemsInState(search(items, e.target.value));
    }

    return (
        <>
            <h1 class="text-center text-uppercase border-bottom">{transl(itemname + 's')}</h1>
            <input type="text" className="form-control" id="searchinput" aria-describedby="emailHelp" placeholder={placeholder} onKeyUp={handleKeyUp} />
            <Container fluid={true} className="mt-4">
                <table className="table table-sm">
                    <thead className="thead-dark">
                        <tr className="text-uppercase">{headerRow}</tr>
                    </thead>
                    <tbody>
                        {
                            !removeAdd ?
                                <tr key="new" className="font-weight-bold"><td><a href={addLocation}><i className="fa fa-plus-square" aria-hidden="true"></i></a></td><td colSpan="10%"><a href={addLocation}> Add new {itemname}</a></td></tr>
                                :
                                <></>
                        }
                        {tbody}
                    </tbody>
                </table>
            </Container>
        </>
    );
}
