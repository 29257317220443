import { useTranslation } from 'react-i18next';

export const useT = () => {
    const { t } = useTranslation();

    function transl(text) {
        return t(text.toLowerCase());
    }

    return { transl };
}
