import React from 'react';
import { normalizeDate } from './../../Modules/Helpers/Helpers';
import { SearchDropdown } from './../../Modules/SearchDropdown/SearchDropdown';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useT } from './../../Modules/Helpers/Translation';

export const AdminItemForm = (props) => {
    const { transl } = useT();
    const {
        item,
        exempt,
        itemname,
        searchDropdownList,
        disable,
        allow,
        booleanList
    } = props;

    let isAdd = item == null || item.id == 0;

    let disabled = ['id', 'submittedDate', 'lastUpdated'].concat(disable);

    let textarea = ['abstract', 'content', 'adminNotes'];
    let textareaNoWyswig = ['interests'];
    let downloads = ['cv'];
    let booleans = [].concat(booleanList);
    let booleansToSubmit = [];

    let formHtml = [];

    let shouldDisplay = (propName) => {
        return !(propName.indexOf("Select") > 0);
    }

    let outputDefault = (item, propName, output) => {
        if (output.length == 0) {
            output.push(<input type="text" name={propName} className="form-control" id={propName + "input"} aria-describedby={propName + "Help"} defaultValue={item[propName]} readOnly={disabled.includes(propName) ? "readonly" : null} />);
        }
    }

    let outputDownload = (item, propName, output) => {
        if (downloads.includes(propName)) {
            output.push(<div><a href={"api/client/download/" + item.id}>Download</a><div><input type="File" name="CvFile" /></div></div>);
        }
    }

    let outputTextarea = (item, propName, output) => {
        if (textarea.includes(propName)) {
            output.push(<input type="hidden" name={propName} id={propName} />);
            output.push(
                <CKEditor
                    editor={ClassicEditor}
                    data={item[propName]}
                    onInit={editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        document.getElementById(propName).value = data;

                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />);
        }
        if (textareaNoWyswig.includes(propName)) {
            output.push(<textarea className="form-control" name={propName} id={propName} >{item[propName]}</textarea>);
        }
    }

    let outputCheckbox = (item, propName, output) => {
        if (booleans.includes(propName)) {
            booleansToSubmit.push(propName);
            output.push(<div><input type="checkbox" name={propName} id={propName + "input"} aria-describedby={propName + "Help"} defaultChecked={item[propName]} id={propName + "input"} /></div>);
        }
    }

    let outputDate = (item, propName, output) => {
        if (propName.toLowerCase().indexOf("date") !== -1) {
            output.push(<input type="text" name={propName} key={propName} className="form-control" id={propName + "input"} aria-describedby={propName + "Help"} defaultValue={item[propName]} readOnly={disabled.includes(propName) ? "readonly" : null} />);
        }
    }

    let outputSelect = (item, propName) => {
        if (propName.indexOf("level_title") === 0) {
            let base = propName.substring(0, propName.indexOf("_title"));
            let selectList = [];
            let count = 0;
            let done = false;
            while (!done) {
                if (item[base + "Select_" + count + "_" + base + "Id"] != null) {
                    selectList.push(<option selected={item[propName] == item[base + "Select_" + count + "_title"]} value={item[base + "Select_" + count + "_" + base + "Id"]}>{item[base + "Select_" + count + "_title"]}</option>)
                    count++;
                } else {
                    done = true;
                }
            }
            return (<div><label>{base}</label>
                <select name={base + '.' + base + 'Id'} className="form-control">
                    {selectList}
                </select>
            </div>);
        }
        return;
    }

    for (var propName in item) {
        if (shouldDisplay(propName)) {
            formHtml.push(outputSelect(item, propName));
            if (!(propName.indexOf("_") > 0) || allow.includes(propName)) {
                if (propName.toLowerCase().indexOf("date") !== -1) {
                    item[propName] = normalizeDate(item[propName]);
                }
                if (!exempt.includes(propName)) {
                    let output = [];
                    outputDownload(item, propName, output);
                    outputTextarea(item, propName, output);
                    outputCheckbox(item, propName, output);
                    outputDate(item, propName, output);
                    outputDefault(item, propName, output);
                    formHtml.push(<div><label>{transl(propName)}</label>{output}</div>);
                }
            }
        }
    }


    let redirect = () => {
        window.location.href = "/sudo/" + itemname + "s";
    }

    let updateItem = (event) => {
        event.preventDefault();
        if (window.confirm("UPDATE this " + itemname + "?")) {
            const data = new FormData(event.target);

            for (var i in booleansToSubmit) {
                var l = booleans[i] + "input";
                data.set(booleans[i], (document.getElementById(l).checked).toString());
            }

            searchDropdownList.forEach(dropdown => {
                let searchEle = document.getElementById(dropdown.label);
                if (searchEle) {
                    if (!isAdd) {
                        searchEle.value.split(",").map(x => {
                            data.append(dropdown.label + 'Ids', x);
                            return parseInt(x);
                        });
                    }
                }
            });

            var fileField = document.querySelector('input[type="file"]');
            if (fileField != null) {
                data.append('cvfile', fileField.files[0]);
            }
            console.log(data);
            fetch(window.location.origin + "/api/" + itemname + "/update", {
                method: 'POST',
                body: data
            })
                .then(res => {
                    redirect();
                });
        }
    };

    let removeItem = (event) => {
        event.preventDefault();
        if (window.confirm("DELETE this " + itemname + "?")) {
            fetch(window.location.origin + "/api/" + itemname + "/remove", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(item.id),
            })
                .then(res => {
                    redirect();
                });
        }
    };

    return (
        <div>
            <h1 class="text-center text-uppercase border-bottom">{transl(itemname)}</h1>
            <form onSubmit={updateItem}>
                {searchDropdownList.map((dropdown, i) => {
                    return (<SearchDropdown id={dropdown.label} label={dropdown.label} defaultValues={dropdown.defaultValues} placeholder={dropdown.placeholder} options={dropdown.options} />)
                })}
                {formHtml}
                {isAdd ?
                    <div className="row">
                        <div className="col-sm-12">
                            <input type="submit" value={"add " + itemname} className="text-center btn btn-primary" />
                        </div>
                    </div>

                    :

                    <div className="row">
                        <div className="col-sm-6">
                            <button className="text-center btn btn-warning" onClick={removeItem}><i class="fas fa-exclamation-triangle"></i> {transl("remove")}</button>
                        </div>
                        <div className="col-sm-6">
                            <input type="submit" value={transl("submit")} className="text-center btn btn-primary" />
                        </div>
                    </div>
                }

            </form>
        </div>
    );
}
