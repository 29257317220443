import React from 'react';

export const CheckboxList = (props) => {
    const { values, label, stateObject, setStateObject } = props;

    const handleChange = (e) => {
        if (e.target.checked) {
            setStateObject([...stateObject, e.target.value]);
        } else {
            setStateObject(stateObject.filter(x => x !== e.target.value));
        }
    };

    return (
        <>
            <em>{label}</em>
            {values.map(x => {
                return (<div className="form-check">
                    <input type="checkbox" className="form-check-input" id={x} value={x} onChange={handleChange} />
                    <label className="form-check-label" htmlFor={x}>{x}</label>
                </div>);
            })}
        </>
    )
}

export default CheckboxList