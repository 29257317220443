import React, { Component, useState } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Pages/Lead/Home/Home';
import { Offers } from './components/Pages/Lead/Offers/Offers';
import { Offer } from './components/Pages/Lead/Offer/Offer';
import { Register } from './components/Pages/Lead/Register/Register';
import { Contact } from './components/Pages/Lead/Contact/Contact';

const App = () => {
  window.scrollTo(0, 0);
  return (
    <Layout>
      <Route exact path='/' component={Home} />
      <Route path='/aanmelden' component={Register} />
      <Route path='/opdrachten' component={Offers} />
      <Route path='/opdracht/:id' component={Offer} />
      <Route path='/contact' component={Contact} />
    </Layout>
  );
}

export default App;