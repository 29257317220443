import React, { useState, useEffect } from 'react';

import { stateObjects, stateObject } from './../../../Modules/Helpers/Helpers';
import { Table } from './../../../Modules/Table/Table';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const Clients = () => {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);

    let exempt = ["abstract", "removed", "cvExtension", 'adminNotes', 'disciplines', 'interests', 'availibility', 'dateAvailable'];
    let allow = ["level_title"];

    useEffect(() => {
        fetch(window.location.origin + "/api/client/sudo")
            .then(res => res.json())
            .then(
                (result) => {
                    setClients(stateObjects(result));
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, [])

    return (
        <div>
            <Container fluid={true} className="mt-4">
                {loading ?
                    <div>loading...</div>
                    :
                    <Table
                        items={clients}
                        itemname="client"
                        exempt={exempt}
                        allow={allow}
                        subList={[{
                            name: "applications",
                            fields: ["offer_id", "offer_title"]
                        }]} />}
            </Container>
        </div>
    );
}