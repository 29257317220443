import React, { useState, useEffect } from 'react';

import { stateObject } from './../../../Modules/Helpers/Helpers';
import { AdminItemForm } from '../../../Modules/AdminItemForm/AdminItemForm';
import { Container } from 'reactstrap';
import { renderToString } from 'react-dom/server'
import * as defaults from './DefaultValues.js';

export const Offer = (props) => {
    const id = window.location.pathname.split('/')[3];
    const [offer, setOffer] = useState();
    const [applications, setApplications] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [disciplinesDd, setDisciplinesDd] = useState({});
    const [applicationsDd, setApplicationsDd] = useState({});

    let exempt = ['removed', 'approved', 'applications', 'disciplines'];
    let allow = [];
    let booleanList = ['visible'];

    useEffect(() => {
        fetch(window.location.origin + "/api/offer/sudo/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    if (id != 'add') {
                        setApplications(result.applications.map(app => {
                            return app.clientId
                        }));
                        setDisciplines(result.disciplines.map(app => {
                            return app.disciplineId
                        }));
                    } else {
                        result.abstract = renderToString(defaults.abstract);
                        result.content = renderToString(defaults.content);
                    }
                    setOffer(stateObject(result));
                },
                (error) => {
                    console.log(error);
                }
            );
    }, []);

    useEffect(() => {
        if (id != 'add') {
            fetch(window.location.origin + "/api/client/sudo")
                .then(res => res.json())
                .then(
                    (result) => {
                        setApplicationsDd({
                            label: "Application",
                            placeholder: "Clients",
                            options: result.map(client => {
                                return ({
                                    key: client.id,
                                    text: client.id + " | " + client.firstname + " " + client.lastname,
                                    value: client.id
                                });
                            }),
                            defaultValues: applications
                        },
                            (error) => {
                                console.log(error);
                            }
                        );
                    });
        }
    }, [applications]);

    useEffect(() => {
        if (id != 'add') {
            fetch(window.location.origin + "/api/discipline")
                .then(res => res.json())
                .then(
                    (result) => {
                        setDisciplinesDd({
                            label: "Discipline",
                            placeholder: "Disciplines",
                            options: result.map(discipline => {
                                return ({
                                    key: discipline.disciplineId,
                                    text: discipline.title,
                                    value: discipline.disciplineId
                                });
                            }),
                            defaultValues: disciplines
                        });
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }, [disciplines]);

    return (
        <div>
            <Container className="mt-4">
                <AdminItemForm
                    item={offer}
                    itemname="offer"
                    exempt={exempt}
                    allow={allow}
                    booleanList={booleanList}
                    searchDropdownList={[applicationsDd, disciplinesDd]}
                />
            </Container>
        </div>
    );
}