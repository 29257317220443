import React from 'react';

import './Section.css';

export const SectionRegular = (props) => {
    const {
        dark = false,
        className,
        id
    } = props;
    return (
        <div>
            <section id={id} className={"container-fluid " + (dark ? 'blocks' : 'full') + " " + className}>
                <div className="container">
                    <div className="row">
                        {props.children}
                    </div>
                </div>
            </section>
        </div >
    );
}
