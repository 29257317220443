import React, { Component, useState } from 'react';
import { Redirect } from 'react-router-dom'
import { Container } from 'reactstrap';
import { AdminHeader } from './Modules/AdminHeader/AdminHeader';
import { AdminLogin } from './Modules/AdminLogin/AdminLogin';
import { useT } from './Modules/Helpers/Translation';
import './style/Base.css';
import './style/Color.css';
import './style/Form.css';


export const LayoutAdmin = (props) => {
    const { transl } = useT();
    const [loggedIn, setLoggedIn] = useState(false);
    const [toHome, setToHome] = useState(false);
    const parentPath = window.location.pathname.split('/')[2];

    let navlinks = [
        { name: transl("clients"), url: "/sudo/clients" },
        { name: transl("offers"), url: "/sudo/offers" },
        { name: transl("applications"), url: "/sudo/applications" },
    ];

    if (!loggedIn) {
        navlinks = [
            { name: "", url: "" },
        ];
    }

    let adminLogin = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        fetch(window.location.origin + "/api/login", {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setLoggedIn(result);
                })
    }

    if (parentPath == "logout") {
        fetch(window.location.origin + "/api/logout")
            .then(() => {
                setLoggedIn(false);
                setToHome(true);
            });
    }

    fetch(window.location.origin + "/api/loggedIn")
        .then(res => res.json())
        .then(
            (result) => {
                if (!loggedIn) {
                    setLoggedIn(result);
                }
            });

    return (
        <>
            {toHome ? <Redirect to="/sudo" /> : null}
            <AdminHeader home="/sudo" links={navlinks} />
            <div id="main">
                <div id="admin-content">
                    {!loggedIn
                        ?
                        <AdminLogin adminLogin={adminLogin} />
                        :
                        <Container fluid={true} className="p-0">
                            {props.children}
                            <div className="push"></div>
                        </Container>
                    }
                </div>
            </div>
        </>
    );
}