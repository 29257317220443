import React, { useState, useEffect } from 'react';

import { stateObjects, stateObject } from './../../../Modules/Helpers/Helpers';
import { Table } from './../../../Modules/Table/Table';
import { Container } from 'reactstrap';

export const Offers = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);

    let exempt = ['abstract', 'removed', 'content', 'adminNotes', 'disciplines'];
    let allow = ['level_title'];
    let booleanList = ['visible'];

    useEffect(() => {
        fetch(window.location.origin + "/api/offer/sudo")
            .then(res => res.json())
            .then(
                (result) => {
                    setOffers(stateObjects(result));
                    console.log(stateObject(result[0]));
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, [])

    return (
        <div>
            <Container fluid={true} className="mt-4">
                {loading ?
                    <div>loading...</div>
                    :
                    <Table items={offers}
                        itemname="offer"
                        exempt={exempt} allow={allow}
                        booleanList={booleanList}
                        subList={[{
                            name: "applications",
                            fields: ["client_id", "client_firstname", "client_lastname"]
                        }]} />}
            </Container>
        </div>
    );
}