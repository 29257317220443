export function search(arr, query) {
    query = query.replaceAll(",", "");
    let queryArray = query.match(/[^ ]+/g);

    console.log(query);

    let result = [];

    if (queryArray == null) {
        return arr;
    }

    result = arr.filter((item) => {
        let display = 0;
        queryArray.forEach(element => {
            for (var key in item) {
                let value = String(item[key]);

                if (value.toLowerCase().includes(element.toLowerCase())) {
                    display++;
                    break;
                }
            }
        });
        return display == queryArray.length;
    });
    return result;
}

export function normalizeDate(dateString) {
    var date = new Date(dateString);
    return date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
}

export function stateObjects(ob) {
    ob.forEach(function (item, index) {
        ob[index] = stateObject(item);
    });
    return ob;
}

export function stateObject(ob) {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = stateObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[i + '_' + x] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}