import React, { Component, useState } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './Modules/Header/NavMenu';
import { Footer } from './Modules/Footer/Footer';
import './style/Base.css';
import './style/Color.css';
import './style/Form.css';

export const Layout = (props) => {
  let navlinks = [
    { name: "Home", url: "/" },
    { name: "CV Indienen", url: "/aanmelden" },
    { name: "Opdrachten", url: "/opdrachten" },
    { name: "Contact", url: "/contact" },
  ];

  return (
    <>
      <div id="main">
        <NavMenu home="/" links={navlinks} />
        <Container fluid={true} className="p-0">
          {props.children}
        </Container>
        <div className="push"></div>
      </div>
      <Footer />
    </>
  );
}
