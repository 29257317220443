import React, { useState, useEffect } from 'react';

import { stateObject } from '../../../Modules/Helpers/Helpers';
import { AdminItemForm } from '../../../Modules/AdminItemForm/AdminItemForm';
import { Container } from 'reactstrap';

export const Application = (props) => {
    const id = window.location.pathname.split('/')[3];
    const [application, setApplication] = useState();

    let exempt = ['client', 'offer'];
    let allow = ['client_email', 'client_firstname', 'client_lastname', 'client_firstname', 'offer_title'];
    let disable = ['clientId', 'offerId'].concat(allow);
    let booleanList = ['shortlist', 'clientSubmit'];

    useEffect(() => {
        fetch(window.location.origin + "/api/application/sudo/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    setApplication(stateObject(result));
                },
                (error) => {
                    console.log(error);
                }
            );
    }, []);

    return (
        <div>
            <Container className="mt-4">
                <AdminItemForm
                    itemname="application"
                    item={application}
                    exempt={exempt}
                    disable={disable}
                    allow={allow}
                    booleanList={booleanList}
                    searchDropdownList={[]}
                />
            </Container>
        </div>
    );
}