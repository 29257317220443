import React from 'react';
import { Link } from "react-router-dom";
import './Card.css';
import { FadeIn } from './../../Modules/FadeIn/FadeIn';
import { normalizeDate } from './../../Modules/Helpers/Helpers';

export const Card = (props) => {
    const { item } = props;

    return (
        <div className="container">
            <Link to={"/opdracht/" + item.id}>
                <FadeIn>
                    <article>
                        <div className="col-sm-12 offset-sm-1 cardblock row disable-user-select">
                            <div className="col-5 cardleft">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul>
                                            <li><i className="fas fa-map-marker"></i> {item.location}</li>
                                            <li><i className="fas fa-user-graduate"></i> {item.level_title}</li>
                                            <li><i className="fas fa-calendar-alt"></i> {item.duration}</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul>
                                            <li><i className="fas fa-clock"></i> {item.hours} uur</li>
                                            <li><i className="fas fa-calendar"></i> {normalizeDate(item.startdate)}</li>
                                            <li><i className="fas fa-coins"></i> {item.rate}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-7 cardright">
                                <h3>{item.title}</h3>
                                <strong>{item.subtitle}</strong>
                                <p dangerouslySetInnerHTML={{ __html: item.abstract }} className="subtext"></p>
                                <strong><i className="fas fa-hourglass-half"></i> Verloopt op {normalizeDate(item.expiredate)}</strong>
                            </div>
                        </div>
                    </article>
                </FadeIn>
            </Link>
        </div>
    );
}
