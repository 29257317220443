import React, { useState, useEffect } from 'react';
import { RegisterForm } from '../../../Modules/RegisterForm/RegisterForm';
import { SectionRegular } from '../../../Modules/Section/SectionRegular';
import { Jumbotron } from '../../../Modules/Jumbotron/Jumbotron';
import { FadeIn } from '../../../Modules/FadeIn/FadeIn';

import "react-datepicker/dist/react-datepicker.css";

export const Register = () => {
    return (
        <div>
            <Jumbotron small="true">
                <h1>CV Indienen</h1>
            </Jumbotron>
            <FadeIn>
                <SectionRegular>
                    <article className="col-sm-12">
                        <h3>Uw CV indienen</h3>
                        <p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        <RegisterForm />
                    </article>
                </SectionRegular>
            </FadeIn>
            <SectionRegular dark={true}>
                <article className="col-sm-12">
                    <FadeIn>
                        <h3>De snelste en de beste</h3>
                        <p>Wij kunnen niet beter niet sneller en niet slimmer, meld u nu aan!</p>
                    </FadeIn>
                </article>
            </SectionRegular>
            <SectionRegular className="big">
                <article className="col-sm-5">
                    <FadeIn>
                        <h3>De eerste in Nederland.</h3>
                        <p>Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. <b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b>. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla. </p>

                        <p>Sed dignissim lacinia nunc. <b>Lorem ipsum dolor sit amet, consectetur adipiscing elit</b>. Curabitur tortor. Pellentesque nibh. Aenean quam. In scelerisque sem at dolor. Maecenas mattis. Sed convallis tristique sem. Proin ut ligula vel nunc egestas porttitor. <i>Lorem ipsum dolor sit amet, consectetur adipiscing elit</i>. Morbi lectus risus, iaculis vel, suscipit quis, luctus non, massa. </p>

                    </FadeIn>
                </article>
                <article className="col-sm-6 offset-sm-1">
                    <FadeIn>
                        <h3>De Snelheid van BK.</h3>
                        <ul className="spaced">
                            <li>Wij regelen alles voor u, u hoeft niets meer te doen</li>
                            <li>Snel en gemakkelijk</li>
                            <li>Geheel vrijblijvend</li>
                            <li>No cure no pay</li>
                            <li>Wij regelen alles voor u, eer te doen</li>
                            <li>No cure no pay</li>
                        </ul>
                    </FadeIn>
                </article>
            </SectionRegular>
        </div>
    );
}
