import React, { Component, useEffect } from 'react';
import { Fade } from 'react-slideshow-image';
import './Jumbotron.css';

import jmbimg from './zuidas.png';
import jmbimg2 from './newyork.png';

export const Jumbotron = (props) => {
    const { small } = props;

    const fadeImages = [
        "https://images.pexels.com/photos/618613/pexels-photo-618613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        "https://images.pexels.com/photos/2041627/pexels-photo-2041627.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
        /*"https://image.shutterstock.com/z/stock-photo-young-group-of-people-architects-discussing-business-plans-289044179.jpg",
        "https://image.shutterstock.com/z/stock-photo-group-of-business-people-business-people-sharing-their-ideas-502885915.jpg",*/
    ];

    const fadeProperties = {
        duration: 6000,
        transitionDuration: 1500,
        infinite: true,
        indicators: false,
        arrows: false,

        onChange: (oldIndex, newIndex) => {
        }
    }

    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    useEffect(() => {
        shuffleArray(fadeImages);
    }, [])

    return (
        <div id="jumbotron" className={"jumbotron jumbotron-fluid p-5" + (small ? ' small' : '')}>

            <div className="container">
                {props.children}
            </div>

            <div id="jumbotron-image" className="slide-container">
                <Fade {...fadeProperties}>
                    {fadeImages.map(x => {
                        return (
                            <div className="each-fade">
                                <div className="image-container">
                                    <img src={x} />
                                </div>
                            </div>
                        );
                    })}
                </Fade>
            </div>
            <div id="jumbotron-overlay"></div>
        </div>
    );
}
