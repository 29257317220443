import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import logo from './jobLogo.png';
import { Jumbotron } from './../../../Modules/Jumbotron/Jumbotron';
import { SectionRegular } from './../../../Modules/Section/SectionRegular';
import { FadeIn } from './../../../Modules/FadeIn/FadeIn';
import { FadeOut } from './../../../Modules/FadeOut/FadeOut';

export const Home = () => {
  return (
    <div>
      <Jumbotron>
        <h1 className="display-4 mb-5"><strong>Hello, world!</strong></h1>
        <p className="lead">This is a small amount of text.</p>
        <p className="lead">This is a bit more text.</p>
        <p id="button-container">
          <Link className="button" to="/Aanmelden">CV Indienen <i className="fas fa-angle-right"></i></Link>
          <Link className="button secondary" to="/Opdrachten">Opdrachten</Link>
        </p>
        <div id="arrow-container">
          <FadeOut>
            <div>
              <i className="fa fa-angle-down fa-4x"></i>
            </div>
          </FadeOut>
        </div>
      </Jumbotron>
      <SectionRegular dark={true} className="big">
        <div className="container">
          <div className="row">
            <article className="col-md-12">
              <FadeIn>
                <h1 className="alt">Samenwerken met resourcepoint</h1>
                <div className="container">
                  <div class="row">
                    <em class="offset-md-2 col-md-8">
                      Het platform in Nederland voor het samenbrengen van vraag en aanbod op een gemakkelijke en transparante manier. Dit doen wij voor onze partners, ZZPers maar ook opdrachtgevers.
                    </em>
                  </div>
                </div>
              </FadeIn>
            </article>
          </div>
          <div className="row">

            <article className="col-sm-3">
              <FadeIn>
                <div className="mb-4"><i className="fa fa-user-tie fa-4x"></i></div>
                <strong>Een nieuwe opdracht</strong>
                <p>We bevinden ons in een bijzondere tijd. Dit zorgt voor
                                onzekerheid en roept vragen.</p>
              </FadeIn>
            </article>

            <div className="col-sm-1">
              <div className="spacer"></div>
            </div>
            <article className="col-sm-4">
              <FadeIn>
                <div className="mb-4"><i className="fa fa-hands-helping fa-4x"></i></div>
                <strong>Een nieuwe medewerker</strong>
                <p>Juist in deze tijd is BK
                            er voor de ondernemers waar we mee samenwerken.</p>
              </FadeIn>
            </article>
            <div className="col-sm-1">
              <div className="spacer"></div>
            </div>
            <article className="col-sm-3">
              <FadeIn>
                <div className="mb-4"><i className="fa fa-glass-cheers fa-4x"></i></div>
                <strong>Een nieuw begin</strong>
                <p>Dit zorgt voor
                                onzekerheid en roept vragen opJuist in deze tijd is.</p>
              </FadeIn>
            </article>
          </div>
        </div>
      </SectionRegular>
      <SectionRegular dark={false} className="big">
        <article className="col-sm-4">
          <FadeIn>
            <h3>Hello, world</h3>
            <p>We bevinden ons in een bijzondere tijd. Dit zorgt voor
            onzekerheid en roept vragen opJuist in deze tijd is BK
                            er voor de ondernemers waar we mee samenwerken.</p>
            <p>Heb je vragen? Bekijk de Q&A of contact onze afdeling Support
                            (support@BK.nl, 031 - 555 22 142).</p>
          </FadeIn>
        </article>
        <div className="col-sm-4"></div>
        <article className="col-sm-4 img-container">
          <FadeIn>
            <img src={logo} />
          </FadeIn>
        </article>
      </SectionRegular>
      <Container>
      </Container>
    </div >
  );
}
