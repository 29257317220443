import React, { useState, useEffect } from 'react';
import * as Validation from 'react-hook-form';
import { DatePicker } from 'react-rainbow-components';

import { CustomModal } from './../../Modules/Modal/CustomModal';
import Moment from 'moment';
import { Slider } from '@material-ui/core';

import "react-datepicker/dist/react-datepicker.css";

export const RegisterForm = (props) => {
    const [offerId, setOfferId] = useState(props.offerId);
    const [dateOfBirth, setDateOfBirth] = useState(new Date());
    const [dateAvailable, setDateAvailable] = useState(new Date());
    const [client, setClient] = useState();
    const [rate, setRate] = useState([60, 120]);
    const [disciplines, setDisciplines] = useState([]);
    const [showTc, setShowTc] = useState(false);

    const { register, handleSubmit, watch, errors } = Validation.useForm();

    useEffect(() => {
        fetch(window.location.origin + "/api/discipline")
            .then(res => res.json())
            .then(res => {
                setDisciplines(res);
            });
    }, []);

    const handleTandC = () => {
        setShowTc(true);
    }

    const submitForm = (data) => {
        data.rateMin = Math.min(...rate);
        data.rateMax = Math.max(...rate);

        var fileField = document.querySelector('input[type="file"]');

        var form_data = new FormData();
        form_data.append('cvFile', fileField.files[0]);

        for (var key in data) {
            form_data.append(key, data[key]);
        }

        if (data.disciplines) {
            for (var i = 0; i < data.disciplines.length; i++) {
                form_data.append('DisciplineIds', Number(data.disciplines[i]));
            }
        }

        setClient({
            email: data.email
        });

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LdJDbEZAAAAAP6lAjEaywKpB6klYMLL4mX0Bpqg', { action: 'submit' }).then(function (token) {
                form_data.append('RecaptchaToken', token);
                window.scrollTo(0, 0);

                fetch(window.location.origin + "/api/client/submit", {
                    method: 'POST',
                    body: form_data
                })
                    .then(res => {
                    });
            });
        });
    }


    const verplicht = <div class="alert alert-danger">Dit veld is verplicht</div>;

    return (
        <>
            {client != null ?
                <>
                    <h3>Aanmelding voltooid</h3>
                    <div>{client.email}</div>
                </>
                :
                <form onSubmit={handleSubmit(submitForm)}>
                    {offerId &&
                        <input type="hidden" name="offerId" ref={register({ required: false })} value={offerId} id="hiddenOfferId" />
                    }
                    <div className="form-group mt-5">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="firstname">Voornaam</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="text" name="firstname" ref={register({ required: true })} className="form-control" placeholder="Voornaam" />
                                {errors.firstname && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="lastname">Achternaam</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="text" name="lastname" ref={register({ required: true })} className="form-control" placeholder="Achternaam" />
                                {errors.lastname && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="email">Email adres</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="email" name="email" ref={register({ required: true })} className="form-control" placeholder="E-mail adres" />
                                {errors.email && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="phone">Telefoonnummer</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="text" name="phone" ref={register({ required: true })} className="form-control" placeholder="Telefoonnummer" />
                                {errors.phone && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="city">Woonplaats</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="text" name="city" ref={register({ required: true })} className="form-control" placeholder="Woonplaats" />
                                {errors.city && verplicht}
                            </div>
                        </div>
                    </div>
                    {!offerId &&
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-2">
                                    <label htmlFor="interests">Vakgebieden</label>
                                </div>
                                <div className="col-sm-10 pl-5">
                                    <div className="row">
                                        {disciplines.map((item, index) => {
                                            return (
                                                <div className="form-check col-sm-4 p-2">
                                                    <input class="discipline" type="checkbox" name="disciplines" value={item.disciplineId} ref={register({ required: true })} className="form-check-input" id={item.title + "-check"} />
                                                    <label className="form-check-label" htmlFor={item.title + "-check"}>{item.title}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {!offerId &&
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-2">
                                    <label htmlFor="interests">Waar ben je naar op zoek?</label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea rows="5" name="interests" ref={register({ required: true })} className="form-control" placeholder="Bijv. baan als project manager, max 25km reizen, startdatum vanaf 25 Juli etc." />
                                    {errors.interests && verplicht}
                                </div>
                            </div>
                        </div>
                    }
                    {offerId &&
                        <div className="form-group">
                            <div className="row">
                                <div className="col-sm-2">
                                    <label htmlFor="applicationContent">Opmerkingen</label>
                                </div>
                                <div className="col-sm-10">
                                    <textarea rows="5" name="applicationContent" ref={register({ required: true })} className="form-control" placeholder="Bijv. bij voorkeur 36 uur per week i.p.v. 40 uur, leaseauto etc." />
                                    {errors.applicationContent && verplicht}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="availability">Uur per week</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="text" name="availability" ref={register({ required: true })} className="form-control" placeholder="Uur per week" />
                                {errors.availability && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="rate">Beschikbaar vanaf</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="hidden" name="dateAvailable" ref={register({ required: true })} value={Moment(dateAvailable).format("MM-DD-yyyy")} id="hiddendateAvailable" />

                                <DatePicker
                                    className="form-data date-picker"
                                    formatStyle="medium"
                                    value={dateAvailable}
                                    onChange={value => setDateAvailable(value)}
                                />
                                {errors.dateAvailable && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="rate">Uurtarief in euro's</label>
                            </div>
                            <div className="col-sm-10 mt-5">
                                <Slider
                                    value={rate}
                                    onChange={(event, newValue) => { setRate(newValue) }}
                                    valueLabelDisplay="on"
                                    aria-labelledby="range-slider"
                                    step={5}
                                    //marks
                                    max={200}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-sm-2">
                                <label htmlFor="exampleFormControlFile1">CV (PDF)</label>
                            </div>
                            <div className="col-sm-10">
                                <input type="file" name="cv" ref={register({ required: true })} className="form-control-file inputfile" id="cv" />
                                <label for="cv"><i class="fas fa-upload mr-2"></i> Upload je CV <em>(.pdf)</em></label>
                                {errors.cv && verplicht}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="offset-sm-2 col-sm-10">
                            <div className="form-check mt-2 mb-2">
                                <input type="checkbox" name="termsandconditions" ref={register({ required: true })} className="form-check-input" id="termsandconditions" />
                                <label className="form-check-label" htmlFor="termsandconditions">Ik ga akkoord met de</label> <a onClick={handleTandC}>algemene voorwaarden</a>.
                                        </div>
                            {errors.termsandconditions && verplicht}
                            <button type="submit" className="btn btn-primary">Versturen</button>
                        </div>
                    </div>
                </form>
            }

            <CustomModal show={showTc} setShow={setShowTc} />
        </>
    );
}
