import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import registerServiceWorker from './registerServiceWorker';
import { loadReCaptcha } from 'react-recaptcha-v3';

import App from './App';
import Sudo from './Sudo';

import './i18n';

console.warn = () => { };
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const parentPath = window.location.pathname.split('/')[1];
let captcha = () => {
  console.log("loaded");
}
loadReCaptcha("6LdJDbEZAAAAAP6lAjEaywKpB6klYMLL4mX0Bpqg", captcha);

if (parentPath === "sudo") {
  ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
      <Sudo />
    </BrowserRouter>,
    rootElement);
} else {
  ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
      <App />
    </BrowserRouter>,
    rootElement);
}

registerServiceWorker();
