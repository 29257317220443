import React, { Component, useState } from 'react';
import { Route } from 'react-router';
import { LayoutAdmin } from './components/LayoutAdmin';
import { Clients } from './components/Pages/Admin/Clients/Clients';
import { Client } from './components/Pages/Admin/Clients/Client';
import { Offers } from './components/Pages/Admin/Offers/Offers';
import { Offer } from './components/Pages/Admin/Offers/Offer';
import { Logout } from './components/Pages/Admin/Logout/Logout';
import { Applications } from './components/Pages/Admin/Applications/Applications';
import { Application } from './components/Pages/Admin/Applications/Application';

const Sudo = () => {
    window.scrollTo(0, 0);

    return (
        <div>
            <LayoutAdmin>
                <Route path='/sudo/clients' component={Clients} />
                <Route path='/sudo/offers' component={Offers} />
                <Route path='/sudo/applications' component={Applications} />
                <Route path='/sudo/logout' component={Logout} />
                <Route path='/sudo/client/:id' component={Client} />
                <Route path='/sudo/offer/:id' component={Offer} />
                <Route path='/sudo/application/:id' component={Application} />
            </LayoutAdmin>
        </div>
    );
}

export default Sudo;