import React, { Component, useState } from 'react';
import { Collapse } from 'reactstrap';

export const Faq = (props) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <div className="mt-3">
            <div className="btn-link disable-user-select" onClick={() => { setCollapsed(!collapsed) }} >
                <i className="fas fa-angle-right"></i> {props.children}
            </div>
            <Collapse isOpen={collapsed}>
                <i>{props.Answer != null ? props.Answer : "Nullam pulvinar ante enim, ut convallis justo commodo et. Praesent blandit, neque a molestie hendrerit, lorem ipsum ultrices neque, quis laoreet metus metus vitae lectus. Quisque non erat ultricies neque congue fringilla. Donec vitae justo varius lorem dapibus posuere. Phasellus urna justo, feugiat ut efficitur sed, venenatis sed libero. Sed ante odio, varius ac euismod eget, consectetur non libero."}</i>
            </Collapse>
        </div>
    );
}
