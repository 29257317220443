import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import './FadeIn.css';

export const FadeIn = (props) => {
    return (
        <div>
            <Fade duration={1000}>
                {props.children}
            </Fade>
        </div >
    );
}