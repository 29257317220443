import React, { useState, useEffect } from 'react';
import { SectionRegular } from '../../../Modules/Section/SectionRegular';
import { Jumbotron } from '../../../Modules/Jumbotron/Jumbotron';
import { normalizeDate, stateObject } from '../../../Modules/Helpers/Helpers';
import { RegisterForm } from '../../../Modules/RegisterForm/RegisterForm';
import { Collapse } from 'reactstrap';

import './Offer.css';

export const Offer = () => {
    const id = window.location.pathname.split('/')[2];
    const [offer, setOffer] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        fetch(window.location.origin + "/api/offer/" + id)
            .then(res => res.json())
            .then(
                (result) => {
                    setDisciplines(result.disciplines);
                    setOffer(stateObject(result));
                },
                (error) => {
                    console.log(error);
                }
            )
    }, []);

    const openRegister = () => {
        window.scrollTo(0, 0);
        setIsOpen(true);
    }

    return (
        <div>
            <Jumbotron small="true">
                <h1>{offer.title}</h1>
            </Jumbotron>
            <Collapse isOpen={isOpen}>
                <SectionRegular className="dark">
                    <article className="col-sm-12">
                        <h1>CV indienen voor {offer.title}</h1>
                        <RegisterForm offerId={id} />
                    </article>
                </SectionRegular>
            </Collapse>
            <SectionRegular className="pb-2" id="opdracht">
                <div className="col-sm-12">
                    <h1>{offer.title}</h1>
                    <h2>{offer.subtitle}</h2>
                    <ul class="pill-list">
                        {disciplines.map(x => {
                            return <li>{x.discipline.title}</li>
                        })}
                    </ul>
                    <em class="pre" dangerouslySetInnerHTML={{ __html: offer.abstract }}>
                    </em>
                    <div className="btn-link disable-user-select" onClick={openRegister} >
                        <button class="btn btn-primary offer-respond">Direct reageren</button>
                    </div>
                    <dl>
                        <div class="row text-center prim-bg">
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-map-marker"></i>
                                <dt>Locatie</dt>
                                <dd>{offer.location}</dd>
                            </div>
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-user-graduate"></i>
                                <dt>Niveau</dt>
                                <dd>{offer.level_title}</dd>
                            </div>
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-calendar-alt"></i>
                                <dt>Contract duur</dt>
                                <dd>{offer.duration}</dd>
                            </div>
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-clock"></i>
                                <dt>Uur per week</dt>
                                <dd>{offer.hours}</dd>
                            </div>
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-calendar"></i>
                                <dt>Startdatum</dt>
                                <dd>{normalizeDate(offer.startdate)}</dd>
                            </div>
                            <div class="col-sm-2 col-6">
                                <i class="fas fa-coins"></i>
                                <dt>Rate</dt>
                                <dd>{normalizeDate(offer.startdate)}</dd>
                            </div>
                        </div>
                    </dl>
                    <p class="pre" dangerouslySetInnerHTML={{ __html: offer.content }}>
                    </p>
                    <em><i class="fas fa-hourglass-half"></i> Verloopt op: {normalizeDate(offer.expiredate)}</em>

                    <div className="btn-link disable-user-select" onClick={openRegister} >
                        <button class="btn btn-primary offer-respond">Direct reageren</button>
                    </div>
                </div>
            </SectionRegular>

            <section id="filter" className="container-fluid full">
                <div className="container">
                    <article>
                    </article>
                </div>
            </section>
            <section className="container-fluid full cardblock-container"></section>
        </div>
    );
}