import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';

import './FadeOut.css';

export const FadeOut = (props) => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    });

    function handleScroll() {
        if (window.scrollY == 0) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    return (
        <div>
            <Fade duration={1000} when={show}>
                {props.children}
            </Fade>
        </div>
    );
}