import React, { useState, useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react'

export const SearchDropdown = (props) => {
    const { id, label, placeholder, options, defaultValues } = props;
    const [values, setValues] = useState(defaultValues);

    const handleChange = (e, { searchQuery, value }) => {
        setValues(value);
    }

    useEffect(() => {
        setValues(defaultValues);
    }, [defaultValues]);

    return (
        <div>
            <label>{label}</label>
            <input type="hidden" name={id} id={id} value={values} />
            <Dropdown
                placeholder={placeholder}
                value={values}
                onChange={handleChange}
                fluid
                multiple
                search
                selection
                options={options}
            />
        </div>
    )
}

export default SearchDropdown