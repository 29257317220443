import React, { useState, useEffect } from 'react';

export const Logout = (props) => {
    const { logoutCallback } = props;


    return (
        <div>
        </div>
    );
}