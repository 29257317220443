import React, { useState, useEffect } from 'react';

import { stateObjects, stateObject } from '../../../Modules/Helpers/Helpers';
import { Table } from '../../../Modules/Table/Table';
import { Container } from 'reactstrap';

export const Applications = () => {
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);

    let exempt = ['abstract', 'content', 'adminNotes'];
    let allow = ['client_email', 'client_firstname', 'client_lastname', 'client_firstname', 'offer_title'];
    let booleanList = ['shortlist', 'clientSubmit'];

    useEffect(() => {
        fetch(window.location.origin + "/api/application/sudo")
            .then(res => res.json())
            .then(
                (result) => {
                    setApplications(stateObjects(result));
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                }
            )
    }, [])

    return (
        <div>
            <Container fluid={true} className="mt-4">
                {loading ?
                    <div>loading...</div>
                    :
                    <Table
                        items={applications}
                        itemname="application"
                        exempt={exempt}
                        allow={allow}
                        removeAdd={true}
                        booleanList={booleanList}
                        subList={[{
                            name: "applications",
                            fields: ["client_id", "client_firstname", "client_lastname"]
                        }]} />}
            </Container>
        </div>
    );
}